import https from "@/router/https";

// 获取红包雨详情
export function getRain(id) {
  return https.fetchGet("/mallapp/redenvelope/" + id);
}

// 应用
export function getRainAward(param) {
  return https.fetchPost("/mallapp/goldrecord/getAward1", param);
}

export function getRainAward2(param) {
  return https.fetchPost("/mallapp/goldrecord/getAward2", param);
}

// 分享加次数
export function rainShare(param) {
  return https.fetchPost("/mallapp/redenvelope/share", param);
}
