<template>
  <div style="height: 100%; display: flex; flex-direction: column">
    <!-- 导航 -->
    <van-nav-bar
      title="邀请好友"
      left-arrow
      @click-left="$goBack()"
      :fixed="true"
      placeholder
      style="flex-shrink: 0"
    ></van-nav-bar>
    <!-- 导航 -->
    <div class="pic">
      <!-- 生成canvas的样本 -->
      <div class="pic-box" ref="imageDom" style="width: 288px; height: 461px">
        <img src="/img/share.png" alt="" style="width: 100%; height: 100%" />
        <div
          ref="qrCreate"
          class="qr"
          :style="'width: 84px; height: 84px; bottom: 216px'"
        ></div>
        <div class="code" style="bottom: 108px; font-size: 14px">
          {{ code }}
        </div>
      </div>

      <!-- 生成的canvas base64赋值给src的图片 -->
      <img class="img-box" src="" alt="" ref="imgDom" style="display: none" />
    </div>
    <!-- 生成的canvas -->
    <canvas
      v-if="show"
      id="myCanvas"
      width="288"
      height="461"
      style="width: 288px"
    ></canvas>
    <div style="height: 130px; flex-shrink: 0"></div>
    <!-- 底部 -->
    <div class="bottom">
      <div>
        <p class="title">分享赚取更多</p>
      </div>
      <div class="bottom-list">
        <div style="width: 100px" @click="share(0)">
          <img src="/img/weixin.png" alt="" style="width: 32px" />
          <p>微信</p>
        </div>
        <div style="width: 100px" @click="share(1)">
          <img src="/img/pengyouquan.png" alt="" style="width: 32px" />
          <p>朋友圈</p>
        </div>
        <div style="width: 100px" @click="share(2)">
          <img src="/img/qq.png" alt="" style="width: 32px" />
          <p>QQ</p>
        </div>
      </div>
    </div>
    <!-- 底部 -->
  </div>
</template>

<script>
import { getShare } from "@/api/people";
import { rainShare } from "@/api/redPackageRain";
import { upload } from "@/api/upload";
import html2canvas from "html2canvas";
import QRCode from "qrcodejs2";
export default {
  name: "people",
  components: {},
  data() {
    return {
      code: null,
      show: true,
      src: null,

      red: false,
      redId: null,
    };
  },
  created() {
    if (this.$route.query.WoSession) {
      this.$store.commit("setSession", this.$route.query.WoSession);
    }
    if (this.$route.query.redId) {
      this.red = true;
      this.redId = this.$route.query.redId;
    }
    this.getShare();
  },
  mounted() {},
  methods: {
    // 获取邀请码
    getShare() {
      getShare().then((res) => {
        console.log(res);
        this.code = res.data.data.invite_code;
        this.creatQrCode();
        setTimeout(() => {
          this.createCanvas();
        }, 0);
      });
    },
    // 生成二维码
    creatQrCode() {
      console.log(`${location.origin}/#/sharePage?code=${this.code}`);
      new QRCode(this.$refs.qrCreate, {
        text: `${location.origin}/#/sharePage?code=${this.code}`, // 需要转换为二维码的内容
        width: 84,
        height: 84,
        colorDark: "#000000",
        colorLight: "#fed",
        correctLevel: QRCode.CorrectLevel.H,
      });
    },
    // 生成canvas
    createCanvas() {
      var options = {
        // 画布配置项
        useCORS: true,
        dpi: 300,
        scale: 1,
        canvas: document.querySelector("#myCanvas"),
        width: 288,
        height: 461,
      };
      html2canvas(this.$refs.imageDom, options).then((canvas) => {
        this.$refs.imgDom.src = canvas.toDataURL("image/png");
        this.width = null;
        this.height = null;
        this.codeSize = null;
        this.show = false;
      });
    },
    share(type) {
      const blob = this.base64ToBlob(this.$refs.imgDom.src);
      const file = this.blobToFile(blob, "share.png");
      let formData = new window.FormData();
      formData.append("file", file);
      formData.append("fileType", "image/png");
      formData.append("dir", "material");
      upload(formData).then(async (res) => {
        console.log(res);
        this.src = res.data.data;

        await this.share1(type);
      });
    },
    share1(type) {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

      if (this.red) {
        rainShare({ redId: this.redId }).then((res) => {
          console.log(res);
        });
      }

      if (isIOS) {
        let obj = {
          title: "",
          picUrl: this.src,
          content: "",
          openUrl: "",
          platformId: type,
        };
        window.webkit.messageHandlers.share.postMessage(JSON.stringify(obj));
      }
      if (isAndroid) {
        window.android.shareImg(type, this.src);
      }
    },
    close() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.close.postMessage("up");
      }
      if (isAndroid) {
        window.android.close();
      }
    },

    // base64转blob
    base64ToBlob(base64Data) {
      let arr = base64Data.split(","),
        fileType = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        l = bstr.length,
        u8Arr = new Uint8Array(l);

      while (l--) {
        u8Arr[l] = bstr.charCodeAt(l);
      }
      return new Blob([u8Arr], {
        type: fileType,
      });
    },
    // blob转file
    blobToFile(newBlob, fileName) {
      newBlob.lastModifiedDate = new Date();
      newBlob.name = fileName;
      newBlob = new window.File([newBlob], "png");
      return newBlob;
    },
  },
};
</script>
<style lang="less" scoped>
body {
  -webkit-text-size-adjust: 100% !important;
}
.pic {
  height: calc(100% - 180px);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  .img-box {
    height: 85%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .pic-box {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    .qr {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    .code {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      line-height: 1;
      border-radius: 10px;
      font-weight: 600;
      color: red;
    }
  }
}
.bottom {
  position: fixed;
  width: 100%;
  height: 130px;
  bottom: 0;
  padding: 10px 0 20px;
  background-color: #fff;
  box-sizing: border-box;
  .title {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: #333;
    margin-bottom: 15px;
  }
  .bottom-list {
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
  }
}
</style>
